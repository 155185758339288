import React, { useState, useEffect } from "react";
import { Sidebar } from "../../sidebar/Sidebar";
import Topbar from "../../topbar/Topbar";
import AllData from "../../data/AllData";
import { Typography, Box, Button } from "@mui/material";

export default function PopUp() {
  const [popupData, setPopupData] = useState(null); // To store the 'show' value
  const [popupId, setPopupId] = useState(null); // To store the document ID

  useEffect(() => {
    fetchPopupData();
  }, []);
  console.log("this is popup value:,", popupData);
  const fetchPopupData = async () => {
    try {
      const querySnapshot = await AllData.getPopupValue();
      console.log("QuerySnapshot:", querySnapshot);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        console.log("Document ID:", doc.id);
        console.log("Document Data:", doc.data());

        const docData = doc.data();
        if (docData && typeof docData.show === "boolean") {
          // Use 'Show' with a capital S
          setPopupData(docData.show); // Update to match the field name
          setPopupId(doc.id);
        } else {
          console.warn("The 'Show' field is missing or not a boolean.");
        }
      } else {
        console.warn("No documents found in the 'popUp' collection.");
      }
    } catch (err) {
      console.error("Error fetching popup data:", err);
    }
  };

  const togglePopup = async () => {
    if (popupId !== null) {
      try {
        await AllData.updatePopupValue(popupId, { show: !popupData });
        setPopupData((prev) => !prev); // Update the state locally
      } catch (err) {
        console.error("Error updating popup value:", err);
      }
    }
  };

  return (
    <div>
      <Topbar />
      <div className="popup">
        <Sidebar />
        <Box sx={{ padding: 3, textAlign: "center" }}>
          {popupData !== null ? (
            <>
              <Typography variant="h6">
                Popup is currently: <b>{popupData ? "Shown" : "Hidden"}</b>
              </Typography>
              <Button
                variant="contained"
                color={popupData ? "secondary" : "primary"}
                onClick={togglePopup}
                sx={{ marginTop: 2 }}
              >
                {popupData ? "Hide Popup" : "Show Popup"}
              </Button>
            </>
          ) : (
            <Typography>Loading popup data...</Typography>
          )}
        </Box>
      </div>
    </div>
  );
}
