import React, { useState } from "react";
import "./sidebar.css";
import { Group, LineStyle } from "@material-ui/icons";
import {
  AirlineStops,
  ConnectingAirports,
  FlightClass,
  CurrencyPound,
  Swipe,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

export const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">{!isCollapsed && "Dashboard"}</h3>
          <ul className="sidebarList">
            <li className="sidebarListItem">
              <Link
                to="/admin"
                style={{ textDecoration: "none" }}
                data-tooltip="Home"
              >
                <LineStyle className="sidebarIcons" />
                {!isCollapsed && "Home"}
              </Link>
            </li>
            <li className="sidebarListItem">
              <Link
                to="/airline"
                style={{ textDecoration: "none" }}
                data-tooltip="Airlines"
              >
                <ConnectingAirports className="sidebarIcons" />
                {!isCollapsed && "Airlines"}
              </Link>
            </li>
            <li className="sidebarListItem">
              <Link
                to="/locations"
                style={{ textDecoration: "none" }}
                data-tooltip="Destination"
              >
                <AirlineStops className="sidebarIcons" />
                {!isCollapsed && "Destination"}
              </Link>
            </li>
            <li className="sidebarListItem">
              <Link
                to="/f_classes"
                style={{ textDecoration: "none" }}
                data-tooltip="Classes"
              >
                <FlightClass className="sidebarIcons" />
                {!isCollapsed && "Classes"}
              </Link>
            </li>
            <li className="sidebarListItem">
              <Link
                to="/seasional_fares"
                style={{ textDecoration: "none" }}
                data-tooltip="Seasonal Fares"
              >
                <CurrencyPound className="sidebarIcons" />
                {!isCollapsed && "Seasonal Fares"}
              </Link>
            </li>
            <li className="sidebarListItem">
              <Link
                to="/pop_up"
                style={{ textDecoration: "none" }}
                data-tooltip="PopUp"
              >
                <Swipe className="sidebarIcons" />
                {!isCollapsed && "PopUP"}
              </Link>
            </li>
            <li className="sidebarListItem">
              <Link
                to="/user"
                style={{ textDecoration: "none" }}
                data-tooltip="Users"
              >
                <Group className="sidebarIcons" />
                {!isCollapsed && "Users"}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <button className="collapseButton" onClick={toggleCollapse}>
        {isCollapsed ? "Expand" : "Collapse"}
      </button>
    </div>
  );
};

export default Sidebar;
