import React, { useState, useEffect } from "react";
import { Sidebar } from "../../sidebar/Sidebar";
import Topbar from "../../topbar/Topbar";
import "./addflight.css";
import AllData from "../../data/AllData";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
export const AddFlight = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id"); // Get flight ID from query parameter
  const [flightsdata, setFlightsData] = useState({
    origin: "",
    desti: "",
    airline: "",
    classes: "",
    adult: "",
    child: "",
    infant: "",
    fduration: "",
    fstop: "",
    img: "",
    timeTo: "",
    timeFrom: "",
    retunrtimefrom: "",
    retunrtimeto: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchFlightData(id);
    }
  }, [id]);

  const fetchFlightData = async (flightId) => {
    try {
      const doc = await AllData.getFlight(flightId);
      if (doc.exists()) {
        setFlightsData(doc.data());
      } else {
        console.error("Flight not found");
      }
    } catch (err) {
      console.error("Error fetching flight:", err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFlightsData({ ...flightsdata, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    const {
      origin,
      desti,
      airline,
      classes,
      adult,
      child,
      infant,
      fduration,
      fstop,
      img,
      timeTo,
      timeFrom,
      retunrtimefrom,
      retunrtimeto,
    } = flightsdata;

    if (
      !origin ||
      !desti ||
      !airline ||
      !classes ||
      !adult ||
      !child ||
      !infant ||
      !fduration ||
      !img ||
      !timeTo ||
      !timeFrom
    ) {
      setError("All fields are mandatory!");
      return;
    }

    try {
      if (id) {
        // Update existing flight
        await AllData.updateFlight(id, flightsdata);
        setError("Flight updated successfully!");
      } else {
        // Add new flight
        await AllData.addFlights(flightsdata);
        setError("Flight added successfully!");
      }
      navigate("/admin");
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  return (
    <>
      <Topbar />
      <div className="navaddf">
        <Sidebar />
        <div className="add-f-Container">
          <h1>{id ? "Edit Flight" : "Add Flight"}</h1>
          <div className="addf-form-Container">
            <p>
              <b>Please fill in this form to {id ? "edit" : "add"} a flight.</b>
            </p>
            <hr />
            <form onSubmit={handleSubmit}>
              <label>
                <b>Origin:</b>
              </label>
              <input
                type="text"
                name="origin"
                value={flightsdata.origin}
                onChange={handleChange}
              />

              <label>
                <b>Destination:</b>
              </label>
              <input
                type="text"
                name="desti"
                value={flightsdata.desti}
                onChange={handleChange}
              />

              <label>
                <b>Airline:</b>
              </label>
              <input
                type="text"
                name="airline"
                value={flightsdata.airline}
                onChange={handleChange}
              />

              <label>
                <b>Class:</b>
              </label>
              <input
                type="text"
                name="classes"
                value={flightsdata.classes}
                onChange={handleChange}
              />

              <label>
                <b>Adult Fare:</b>
              </label>
              <input
                type="text"
                name="adult"
                value={flightsdata.adult}
                onChange={handleChange}
              />

              <label>
                <b>Child Fare:</b>
              </label>
              <input
                type="text"
                name="child"
                value={flightsdata.child}
                onChange={handleChange}
              />

              <label>
                <b>Infant Fare:</b>
              </label>
              <input
                type="text"
                name="infant"
                value={flightsdata.infant}
                onChange={handleChange}
              />

              <label>
                <b>Flight Duration:</b>
              </label>
              <input
                type="text"
                name="fduration"
                value={flightsdata.fduration}
                onChange={handleChange}
              />

              <label>
                <b>Stop:</b>
              </label>
              <input
                type="text"
                name="fstop"
                value={flightsdata.fstop}
                onChange={handleChange}
              />

              <label>
                <b>Airline Logo (URL):</b>
              </label>
              <input
                type="text"
                name="img"
                value={flightsdata.img}
                onChange={handleChange}
              />

              <label>
                <b>Time From:</b>
              </label>
              <input
                type="time"
                name="timeFrom"
                value={flightsdata.timeFrom}
                onChange={handleChange}
              />

              <label>
                <b>Time To:</b>
              </label>
              <input
                type="time"
                name="timeTo"
                value={flightsdata.timeTo}
                onChange={handleChange}
              />

              <label>
                <b>Return Time From:</b>
              </label>
              <input
                type="time"
                name="retunrtimefrom"
                value={flightsdata.retunrtimefrom}
                onChange={handleChange}
              />

              <label>
                <b>Return Time To:</b>
              </label>
              <input
                type="time"
                name="retunrtimeto"
                value={flightsdata.retunrtimeto}
                onChange={handleChange}
              />

              {error && <p className="error">{error}</p>}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2, // Space between elements
                  marginBottom: 2,
                  marginTop: 4,
                  // Add some spacing below the search container
                }}
              >
                {" "}
                <Button type="submit" variant="contained" color="primary">
                  {id ? "Update Flight" : "Add Flight"}
                </Button>
              </Box>
              {/* <button type="submit">
                {id ? "Update Flight" : "Add Flight"}
              </button> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFlight;
