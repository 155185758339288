import React, { useState, useEffect } from "react";
import { Sidebar } from "./sidebar/Sidebar";
import Topbar from "./topbar/Topbar";
import "./admin.css";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AllData from "./data/AllData";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
export const Admin = () => {
  const [flightsdata, setFlightsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [originSearch, setOriginSearch] = useState("");
  const [destinationSearch, setDestinationSearch] = useState("");
  const [popupData, setPopupData] = useState(null); // Single popup value
  const navigate = useNavigate();

  useEffect(() => {
    getFlights();
  }, []);

  const getFlights = async () => {
    const data = await AllData.getAllFlights();
    const flightDocs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setFlightsData(flightDocs);
    setFilteredData(flightDocs);
  };

  // Handle search
  const handleSearch = () => {
    const filtered = flightsdata.filter((flight) => {
      const originMatch = flight.origin
        .toLowerCase()
        .includes(originSearch.toLowerCase());
      const destiMatch = flight.desti
        .toLowerCase()
        .includes(destinationSearch.toLowerCase());
      return originMatch && destiMatch;
    });
    setFilteredData(filtered);
  };

  // Handle reset search
  const resetSearch = () => {
    setFilteredData(flightsdata);
    setOriginSearch("");
    setDestinationSearch("");
  };

  // Delete handler
  const deleteHandler = async (id) => {
    await AllData.deleteFlight(id);
    getFlights();
  };

  // Edit handler
  const editHandler = (id) => {
    navigate(`/addflight?id=${id}`);
  };

  return (
    <>
      <Topbar />
      <div className="nav">
        <Sidebar />
        <div className="admin">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2, // Space between elements
              marginBottom: 2, // Add some spacing below the search container
            }}
          >
            <Link to="/addflight">
              <Button onClick={resetSearch} variant="contained" color="primary">
                Add Flight
              </Button>
            </Link>
          </Box>
          {/* Search Fields */}
          <div className="search-container">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2, // Space between elements
                marginBottom: 2, // Add some spacing below the search container
              }}
            >
              <TextField
                id="origin-search"
                label="Origin"
                variant="outlined"
                value={originSearch}
                onChange={(e) => setOriginSearch(e.target.value)}
              />
              <TextField
                id="destination-search"
                label="Destination"
                variant="outlined"
                value={destinationSearch}
                onChange={(e) => setDestinationSearch(e.target.value)}
              />
              <Button
                onClick={handleSearch}
                variant="contained"
                color="primary"
              >
                Search
              </Button>
              <Button
                onClick={resetSearch}
                variant="contained"
                color="secondary"
              >
                Reset
              </Button>
            </Box>
          </div>

          <div className="a-table-Container">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="user-tabel"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Airline Logo</TableCell>
                    <TableCell>Airline Name</TableCell>
                    <TableCell>Origin</TableCell>
                    <TableCell>Destination</TableCell>
                    <TableCell>Stop</TableCell>
                    <TableCell>Class</TableCell>
                    <TableCell>Adult Fare</TableCell>
                    <TableCell>Child Fare</TableCell>
                    <TableCell>Infant Fare</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((doc, index) => {
                    return (
                      <TableRow key={doc.id}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">
                          <div className="img-container">
                            <img
                              className="cellImg"
                              src={doc.img}
                              alt="avatar"
                            />
                          </div>
                        </TableCell>
                        <TableCell align="left">{doc.airline}</TableCell>
                        <TableCell>{doc.origin}</TableCell>
                        <TableCell>{doc.desti}</TableCell>
                        <TableCell>{doc.fstop}</TableCell>
                        <TableCell>{doc.classes}</TableCell>
                        <TableCell>{doc.adult}</TableCell>
                        <TableCell>{doc.child}</TableCell>
                        <TableCell>{doc.infant}</TableCell>
                        <TableCell>{doc.fduration}</TableCell>
                        <TableCell>{doc.timeFrom}</TableCell>
                        <TableCell>{doc.timeTo}</TableCell>
                        <TableCell align="left">
                          <Button
                            className="edit-btn"
                            variant="contained"
                            color="primary"
                            onClick={(e) => editHandler(doc.id)}
                          >
                            Edit
                          </Button>
                          <br />
                          <br />
                          <Button
                            variant="contained"
                            color="secondary"
                            className="delete-btn"
                            onClick={(e) => deleteHandler(doc.id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
